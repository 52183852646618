<template>
  <div class="create-category">
    <el-row type="flex" align="middle" class="page-title">
      <i class="el-icon-menu mr-s" />
      <h3>Thêm danh mục bài viết</h3>
    </el-row>
    <el-row>
      <el-col :span="24" :lg="16">
        <el-card>
          <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="Tên danh mục" prop="name">
              <el-input
                v-model="form.name"
                :disabled="callingAPI"
                placeholder="Tên danh mục"
              />
            </el-form-item>
            <el-form-item label="Mô tả">
              <el-input
                type="textarea"
                :rows="3"
                v-model="form.description"
                :disabled="callingAPI"
                placeholder="Mô tả"
              />
            </el-form-item>
            <el-form-item label="SEO title">
              <el-input
                v-model="form.metaTitle"
                :disabled="callingAPI"
                placeholder="SEO title"
              />
            </el-form-item>
            <el-form-item label="SEO keyword">
              <el-input
                v-model="form.metaKeyword"
                :disabled="callingAPI"
                placeholder="SEO keyword"
              />
            </el-form-item>
            <el-form-item label="SEO description">
              <el-input
                v-model="form.metaDescription"
                :disabled="callingAPI"
                placeholder="SEO description"
              />
            </el-form-item>
          </el-form>
          <el-row type="flex" justify="end" class="mt-xl">
            <el-button
              class="mt-xl"
              type="primary"
              :loading="callingAPI"
              @click="handleCreateCategory"
            >
              Thêm danh mục
            </el-button>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { createCategory } from "@/services/category";

const slugify = require("slugify");
export default {
  name: "CreateCategory",
  data() {
    return {
      callingAPI: false,
      searchPosts: "",

      form: {
        name: "",
        metaTitle: "",
        metaKeyword: "",
        metaDescription: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Tên danh mục không được để trống",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    async handleCreateCategory() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.callingAPI = true;
            const data = {
              name: this.form.name,
              description: this.form.description,
              slug: slugify(this.form.name),
              meta_title: this.form.metaTitle,
              meta_keyword: this.form.metaKeyword,
              meta_description: this.form.metaDescription,
            };
            await createCategory(data);
            this.callingAPI = false;
            this.$router.push({ name: "Categories" });
            this.$notify.success({
              title: "Thành công",
              message: "Tạo danh mục thành công.",
              duration: 2000,
            });
          } catch (error) {
            this.callingAPI = false;
            this.$notify.error({
              title: "Thất bại",
              message: "Tạo danh mục thất bại",
              duration: 2000,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-posts {
  width: 300px;
}
</style>
